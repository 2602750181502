export const BG_PRIMARY = '#DCD0CD';
export const BG_SECONDARY = '#6F635D';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(111 99 93 / 29%)';
// this colors used for green background
export const BG_ALTERNATIVE = '#6F635D';
export const TEXT_PRIMARY_LIGHT = '#DCD0CD';
export const TEXT_PRIMARY = '#6F635D';
export const TEXT_PRIMARY_DARK = '#000';
export const TEXT_SECONDARY = '#DCD0CD';

// Exclusive to template-naomirezky
export const FLOWER_COLOR = '#000';
